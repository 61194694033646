import { Component, OnInit } from '@angular/core'; 
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router'; 
import { NavController } from '@ionic/angular';
import { ProdottiService, Categoria, Prodotto } from "../services/prodotti.service";
import { CartService, Product } from "../services/cart.service";
import { SurfglobalService } from '../services/surfglobal.service';

@Component({
  selector: 'app-tab4',
  templateUrl: './tab4.page.html',
  styleUrls: ['./tab4.page.scss'],
})
export class Tab4Page implements OnInit {
  public mySearch: string ="";
  public Prodotti: Categoria[] = [];
  public ProdottiAll: Categoria[] = [];
   
  constructor(public httpClient: HttpClient, private router: Router, public _mysrv: ProdottiService, private navCtrl: NavController, public _mycart: CartService,  public _myglobal: SurfglobalService) {
    console.log(this._myglobal.license);
  }

  async loadData(){
    this._mysrv.loadProdottiNew();
    this._mysrv.Evento.subscribe(data => {
      this.Prodotti=data; 
      this.ProdottiAll=data; 
      this.ProdottiAll = JSON.parse(JSON.stringify(data));
      console.log(data);});
  }

  onInput(evt) {
    console.log(evt.target.value);
    this.mySearch = evt.target.value;
    if (evt.target.value != null && evt.target.value.length>0) {
      this.Prodotti = JSON.parse(JSON.stringify(this.ProdottiAll));
      this.Prodotti.forEach(element => {
        console.log(element);
        let counter = 0;
        let ProdottiTemp: Prodotto[] =[];
        ProdottiTemp = Array.from(element.prodotti);
        ProdottiTemp.forEach((item,index,obj) => {
          if (item.prodotto.toLowerCase().includes(this.mySearch.toLowerCase())) {
          } else{
            element.prodotti.splice(index-counter, 1);
            counter++;
          }
        });
    });
   
    } else {
      this.Prodotti = JSON.parse(JSON.stringify(this.ProdottiAll));
    }
  }
  onCancel(evt) {
    this.Prodotti = this.ProdottiAll;
  }
  
  _addcart(myProd) {
    console.log(JSON.stringify(myProd.iditem));
    var pro: Product[] = [
      { iditem: myProd.iditem, prodotto: myProd.prodotto, prezzo: myProd.prezzo, qta: 1, img_thumb: myProd.img_thumb}
    ];
    this._mycart.addProduct(pro[0]);
  }

  public doRefresh(event) {
    this.loadData();
    event.target.complete();
  }
  navigate(id){
    this.navCtrl.navigateForward(['/tabs/prodottiedit', id]);
  }
  //ngAfterContentInit(){
   // this.loadData();
  //}
  ngOnInit(){  
  }
  ionViewDidEnter() {
    this.loadData();
  }

}