import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';

import { MessaggiService } from '../services/messaggi.service'


@Component({
  selector: 'app-tab5',
  templateUrl: './tab5.page.html',
  styleUrls: ['./tab5.page.scss'],
})
export class Tab5Page implements OnInit {

  public messages: any = [];
  public idcliente ;
  public cliente;

newMsg='';

@ViewChild(IonContent) content: IonContent
  constructor(private route: ActivatedRoute, private router: Router, public toastController: ToastController, public _mysrv: MessaggiService) {
    this.route.paramMap.subscribe(params => {
      this.idcliente = params.get('id');
      this.cliente = params.get('cli');
      // setTimeout(()=> {
      //   this.content.scrollToBottom(200);
      // }
      // );

   });
   }

  //  getData() {
  //   this.data = Observable
  //   .interval(10000)
  //   .mergeMapTo(this.loadData())
  //   .map(res => res.json());
  // }

  loadData(){
    this._mysrv.loadMessaggiCliente().subscribe((data : any) => {
      this.messages = data;
      console.log(JSON.stringify(data));

     }, error => {
      this.msg= error;
      console.log(error);
      this.showToast("danger");
    });
   }


  sendMessage(){
    this._mysrv.addMessaggioCliente(this.newMsg).subscribe((data : any) => {
      if (data=='errore') {
        this.msg='Impossibile inviare il messaggio';
        this.showToast("danger");
      } else {
        this.loadData();
        
      // setTimeout(()=> {
      //   this.content.scrollToBottom(200);
      // }
      // );
      }
 
     }, error => {
       this.msg= error;
      console.log(error);
      this.showToast("danger");
    });
    this.newMsg='';
      // this.messages.push({
      // user: this.currentUser,
      // createdAt: new Date().getTime(),
      // msg: this.newMsg

      // });
      
  }

  ngOnInit() {
    this.loadData();
  }

  async loadDataRecursive(){
    if (this._mysrv.msgcount>0) {
      this._mysrv.loadMessaggiCliente().subscribe((data : any) => {
        this.messages = data;
      }, error => {
        this.msg= error;
        console.log(error);
        this.showToast("danger");
      });
    }
   }

  private timeoutId: any;
  ionViewDidEnter() {
    this.loadData();
    this.initRefresh();
  }
  ionViewDidLeave() {
    this.stopRefresh();
  }
  private initRefresh() {
    this.timeoutId = setInterval(() => this.loadDataRecursive(), 10000);
  }
  private stopRefresh() {
    clearInterval(this.timeoutId);
  }

  private msg ="";
  public async showToast(_color: string) {

    const toast: any = await this.toastController.create({
      message: this.msg,
      duration: 1500,
      position: "top",
      color: _color
    });
    await toast.present();
      this.msg="";
    }

}
