import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurfglobalService } from "../services/surfglobal.service";

@Injectable({
  providedIn: 'root'
})
export class MessaggiService {
  @Output() Evento = new EventEmitter;

  public msgcount;

  constructor(private httpClient: HttpClient,public _global: SurfglobalService) { }
  
  loadMessaggi(idcliente) {
    let postData = {
            "token": this._global.myToken,
            "idcliente" : idcliente
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

    return this.httpClient.post(this._global.myURL + "MESSAGGI/getmessaggiutente", postData, headers) 
  }

  addMessaggio(idcliente, newMsg: string) {
    let postData = {
            "token": this._global.myToken,
            "idcliente" : idcliente,
            "msg" : newMsg 
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

    return this.httpClient.post(this._global.myURL + "MESSAGGI/addmsgutenteweb", postData, headers) 
  }

  loadMessaggiCliente() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

    return this.httpClient.post(this._global.myURL + "MESSAGGI/getmessaggicliente", postData, headers) 
  }

  addMessaggioCliente(newMsg: string) {
    let postData = {
            "token": this._global.myToken, 
            "msg" : newMsg 
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}
    return this.httpClient.post(this._global.myURL + "MESSAGGI/addmsgclienteweb", postData, headers) 
  }
   
  countMessaggi() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL + "MESSAGGI/getcountmessaggiclientenonletti", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        this.msgcount=data;
       }, error => {
        console.log(error);
      });
  }


  
}
