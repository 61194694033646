import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Injectable } from '@angular/core'; 
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router'; 
import { ActivatedRoute } from '@angular/router';
import { NavController , ToastController } from '@ionic/angular';
import { OrdiniService } from "../services/ordini.service";
import { ProfileService } from '../services/profile.service';
import { SurfglobalService } from "../services/surfglobal.service";
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Plugins, FilesystemDirectory, Capacitor  } from '@capacitor/core';
const { Browser, Filesystem } = Plugins;


@Component({
  selector: 'app-ordini',
  templateUrl: './ordini.page.html',
  styleUrls: ['./ordini.page.scss'],
})
export class OrdiniPage implements OnInit {
  loadAPI: Promise<any>;

  public Profilo: any = [];
  public OrdiniAll: any = [];
  public Ordini: any = [];
  public Prenotazioni: any = [];
  public idcliente;
  public cliente;
  public viewdetail: boolean = false;
  public isprenotazione: boolean = false;
  public myord: any = [];

  paymentAmount: string = '0.00';
  currency: string = 'EUR';
  currencyIcon: string = '€';
  responseData: any = '';

  
  paypalscriptadded: boolean=false;

  constructor(@Inject(DOCUMENT) private document: Document, public _myglobal: SurfglobalService, public httpClient: HttpClient, private route: ActivatedRoute, public toastController: ToastController, private router: Router, private navCtrl: NavController, public _mysrv: OrdiniService, public _myprofile: ProfileService) { 
    this.route.paramMap.subscribe(params => {
      this.idcliente = params.get('id');
      this.cliente = params.get('cli');
   });
     
  }



  loadPP(o){
    if (o.paytype=="P" && (o.paypalid!="" && o.paypalid!=null)) {
      console.log( o.paypalid);
      if (!this.paypalscriptadded) {
      
        const paypalScriptElement: HTMLScriptElement = this.document.createElement('script');
        paypalScriptElement.src = "https://www.paypal.com/sdk/js?client-id=" + o.paypalid + "&currency=EUR&disable-funding=sofort,mybank";
        paypalScriptElement.id = 'paypal-script';
        this.document.head.appendChild(paypalScriptElement);
        this.paypalscriptadded=true;
        console.log("ADDEDSCRIPT");
    }
    let _this = this;
    setTimeout(() => {
      // Render the PayPal button into #paypal-button-container
      <any>window['paypal'].Buttons({
 
        // Set up the transaction
        createOrder: function (data, actions) {

          return actions.order.create({
            purchase_units: [{
              amount: {
                currency_code: 'EUR',
                value: _this.paymentAmount
              },
              description: 'Ordine n.' + o.numeroordine + ' di ' + o.nomeconsegna,
              custom_id: o.idordine,
            }]
          });
        },
 
        // Finalize the transaction
        onApprove: function (data, actions) {
          return actions.order.capture()
            .then(function (details) {
              // Show a success message to the buyer
              let jsonString:string=JSON.stringify(details);
              let base64String:string="";
              console.log(jsonString);
              base64String = btoa(jsonString);
              console.log(base64String);

              _this._mysrv.putPaypal(o.idordine, base64String).subscribe((data : any) => {
                if (data=='errore') {
                    _this.msgtoast='Errore nella registrazione del pagamento';
                    _this.showToast("warning");
                };

              }, error => {
                _this.msgtoast= error;
                _this.showToast("warning");
              }); 
              
              _this.msgtoast='Pagamento completato da ' + details.payer.name.given_name + '!';
              _this.showToast("success");
            })
            .catch(err => {
              console.log(err);
            })
        }
      }).render('#paypal-button-container');
    }, 500)
    }
  }


  pagamentoconsegna() {
    if (this.myord.abilitapagamentoallaconsegna) {
    this._mysrv.pagamentoconsegna(this.myord.idordine).subscribe((data : any) => {
      if (data=='errore') {
          this.msgtoast='Errore nella registrazione del pagamento';
          this.showToast("warning");
      } else {
        this.myback();
        this.loadData();
      };

    }, error => {
      this.msgtoast= error;
      this.showToast("warning");
    }); 
  }
  }
  
  loadData(){
      this._mysrv.loadF_OrdiniCliente().subscribe((data : any) => {
        this.OrdiniAll = data;
        this.Prenotazioni = [];
        this.Ordini = [];
        this.OrdiniAll.forEach((item,index2,obj2) => {
          if (item.indirizzoconsegna!=null && item.indirizzoconsegna!="") {
            item.gmapslink = encodeURI('https://maps.google.com/?q=' + item.indirizzoconsegna);
           }
        });
        for (let p of this.OrdiniAll) {
          if (p.prenotazione === true) {
             this.Prenotazioni.push(p);
          } 
          if (p.prenotazione === false) {
            this.Ordini.push(p);
         }
        }
        console.log(data);
       }, error => {
        console.log(error);
      });
   }

  public doRefresh(event) {
    this.loadData();
    event.target.complete();
  }
  navigate(ord){
    //this.navCtrl.navigateRoot(['/ordineedit', id]);
    this.viewdetail=true;
    this.myord= ord;
    this.paymentAmount = this.myord.totale;
    this.isprenotazione = this.myord.prenotazione;
    this.loadAPI = new Promise((resolve) => {
      console.log('resolving promise...');
      this.loadPP(this.myord);
    });

   
  }
  navigateprodotto(myid){
    this.navCtrl.navigateForward(['/prodottiedit', myid]);
  }
  navigatetomsg(idcliente, cliente){
    this.navCtrl.navigateForward(['/tabs/tab5']);
  }
  ngAfterContentInit(){
    this.loadData();
  }
  ngOnInit(){  
    
  }
  ionViewDidEnter() {
    this.loadData();
  }
  myback(){
    if(this.showPDF){
      this.showPDF=false;
      return;
    }
    if (this.viewdetail){
      this.viewdetail=false;
    } else {
      if (this.idcliente>0) {

     
     this.navCtrl.navigateRoot('/tabs/tab3');
    }
    }
  }

 
  segmentChanged(ev: any) {
    var stato: string;
    if (this.myord.statoordine=="inserito") stato="INS";
    if (this.myord.statoordine=="pronto per il ritiro") stato="DEF";
    if (this.myord.statoordine=="annullato") stato="ANN";
    
    //this._changeStatoOrdine(this.myord.idordine, stato);
  }
 
 _changeStatoOrdine(idordine, stato) {
    this._mysrv.updB_Stato(idordine, stato).subscribe((data : any) => {
      if (data=='errore') {
        // this.msg='Errore nel cambio di stato';
        // this.showToast();
      } else {
        //this.msg='Ordine Aggiornato';
        //this.showToast();
      };
    }, error => {
     // this.msg= error;
      //this.showToast();
    }); 
  }



public showPDF: boolean= false;
private statostampa: boolean = false;
public pdfb64: string =";";
public pdfblob;
public async openPdf() {
  this._mysrv.getpdfOrdine(this.myord.idordine).subscribe((data : any) => {
    this.statostampa=data.statostampa;
    this.pdfb64=data.pdf;
    console.log(this.pdfb64);
    if (this.statostampa) {
      var contentType = "application/pdf";
      this.pdfblob = this.b64toBlob(this.pdfb64, contentType);
      this.showPDF=true;
    }
  }, error => {
  }); 

}
 

b64toBlob(b64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 512;
  
    //decode base64 data
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
  
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
  
        var byteArray = new Uint8Array(byteNumbers);
  
        byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


  async downloadPdf(){
    if (Capacitor.getPlatform() === 'web') {

      var a = document.createElement("a"); //Create <a>
      a.href = "data:application/pdf;base64," + this.pdfb64; //Image Base64 Goes here
      a.download = "HSDoc" + String(this.myord.idordine) + ".pdf"; //File name Here
      a.click(); //Downloaded file


    } else {
    await Filesystem.writeFile({
      data: this.pdfb64,
      path: "HSDoc" + String(this.myord.idordine) + ".pdf",
      directory: FilesystemDirectory.Documents
    }).then(res => {
      this.msgtoast="il file " + "'HSDoc" + String(this.myord.idordine) + ".pdf'" + " è stato scaricato nella cartella Documents.";
      this.showToast("success");   
     });;
    
  }
  }
  
  name = 'Angular ' ;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrvalue;

  openMaps(){
    var a = document.createElement("a"); 
      a.href = this.myord.gmapslink;
      a.target = "_blank" 
      a.click();
  }

  private msgtoast ="";
  public async showToast(_color: string) {

    const toast: any = await this.toastController.create({
      message: this.msgtoast,
      duration: 1500,
      position: "top",
      color: _color
    });
    await toast.present();
      this.msgtoast="";
    }


}
