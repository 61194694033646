import { Component, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService }  from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { NavController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AutenticationService } from '../services/autentication.service';
import { SurfglobalService } from '../services/surfglobal.service';
import { ProfileService } from '../services/profile.service';
import "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from '@capacitor/core';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

    chatid: string="";
    user: SocialUser;
    public isWeb: boolean = false;
    public modeDirectLoginNegozio: boolean=false;
    public loggedIn: boolean;
    public showNegozi: boolean = false;
    public showStore: boolean = false;
    myTokenParent = null;
    DatiPub: any =[];
    DatiNegozi: any ={};
     

    constructor(private authService: AuthService, private navController: NavController, private router: Router, public toastController: ToastController,  private route: ActivatedRoute, public _auth: AutenticationService, public _mysrv: SurfglobalService, public _prof: ProfileService) { 
      if (Capacitor.getPlatform() === 'web') {
        this.showStore=true;
        this.isWeb=true;
      }
      console.log("WEB MODE:" + this.showStore);
      console.log(this.route);
      this.route.paramMap.subscribe(params => {
        this._mysrv.myTokenParent = params.get('token');
        this.myTokenParent = params.get('token');
        if (this._mysrv.myTokenParent!= null && this._mysrv.myTokenParent!="") {
          this.modeDirectLoginNegozio=true;
          if (this.showStore) {
            this.modeDirectLoginNegozio=false;
          }
        }
        console.log("DIRECT MODE:" + this.modeDirectLoginNegozio);
     });

      if (localStorage.getItem("color") == "dark") {
        document.body.classList.toggle('dark', true);
       } else {
        document.body.classList.toggle('dark', false);
       }
    };
    
    async ngOnInit() {
      this._auth.FBToken = localStorage.getItem("FBToken");
      if (this._auth.FBToken != null && this._auth.FBToken != "") {
        //this._auth.FBLoggedIn = true;
        this._auth.FBgetCurrentState();
        this._auth.FBgetUserInfo();
      } else {
        // this._auth.Evento.subscribe(data => {this.DatiPub=data;});
        // this.authService.authState.subscribe((user) => {
        //   this.user = user;
        //   this._auth.user = user;
        //   this.loggedIn = (user != null);
        // });
      }
      this._auth.Guser = JSON.parse(localStorage.getItem("GToken"));
      console.log(this._auth.Guser);
      if (this._auth.Guser[0].authentication.idToken) {
        console.log(this._auth.Guser[0].authentication.idToken);
         this._auth.GgetUserInfo();
      }
      this._auth.datipub(); 
    }

    goNegozi(){
        this.showStore=false;
    }
    goPlayStore(){
      window.open('https://play.google.com/store/apps/details?id=it.forj.homesurfing', '_self');
    }
    goAppStore() {
      this.msg='Homesurfing sarà a breve disponibile su Apple App Store.';
      this.showToast("danger");
    }
  
    // signInWithGoogle(): void {
    //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // }
  
    // signInWithFB(): void {
    //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // }
 
    // signOut(): void {
    //   this.authService.signOut();
    // }
  
    // _followdirect(){
    //   if (this.loggedIn) {
    //     this._auth.follownew(this._mysrv.myTokenParent, this.user.authToken, this.user.idToken, this.user.provider).subscribe((data : any) => {
    //       if (data=='errore') {
    //         this.msg='Credenziali non corrette!';
    //         this.showToast("danger");
    //       } else {
    //         this._mysrv.myToken = data;
    //         localStorage.setItem("tokenparent", this._mysrv.myTokenParent);
    //         localStorage.setItem("tokenfollow", this._mysrv.myToken);
    //         this.msg='Benvenuto in HomeSurfing!';
    //         this.showToast("primary");
    //         this.navController.navigateRoot('/tabs');
    //       };
    //      }, error => {
            
    //     }); 
    //   }
    // }


    // _follow(){
    //   if (this.loggedIn) {
    //     if (this.modeDirectLoginNegozio) {
    //       this._followdirect();
    //     }
    //     else {
    //     this._auth.follownew2(this.user.authToken, this.user.idToken, this.user.provider).subscribe((data : any) => {
    //       if (data=='errore') {
    //         this.msg='Credenziali non corrette!';
    //         this.showToast("danger");
    //       } else {
    //         this.showNegozi=true;
    //         this.DatiNegozi = data;
    //         if (this.DatiNegozi.length==1) {
    //           this._opennegozio(this.DatiNegozi[0]);
    //         }
    //         this.msg='Benvenuto in HomeSurfing!';
    //         this.showToast("primary");
    //       };
    //      }, error => {
            
    //     }); 
    //   } 
    // }
    // }


    _FBfollow(){
      if (this._auth.FBLoggedIn) {
        if (this.modeDirectLoginNegozio) {
          this._FBfollowdirect();
        }
        else {

          if (this._mysrv.myTokenParent) {
            this._auth.follownew(this._mysrv.myTokenParent, this._auth.FBToken, "", "FACEBOOK").subscribe((data : any) => {
              if (data=='errore') {
              } else {
                this._mysrv.myToken = data;
                localStorage.setItem("tokenparent", this._mysrv.myTokenParent);
                localStorage.setItem("tokenfollow", this._mysrv.myToken);
              };
             }, error => {
            }); 
          }


        this._auth.follownew2(this._auth.FBToken, "", "FACEBOOK").subscribe((data : any) => {
          if (data=='errore') {
            this.msg='Credenziali non corrette!';
            this.showToast("danger");
          } else {
            this.showNegozi=true;
            this.DatiNegozi = data;
            if (this.DatiNegozi.length==1) {
              this._opennegozio(this.DatiNegozi[0]);
            }
            this.msg='Benvenuto in HomeSurfing!';
            this.showToast("primary");
          };
         }, error => {
            
        }); 
      } 
    }
    }
    _FBfollowdirect(){
      if (this._auth.FBLoggedIn) {
        this._auth.follownew(this._mysrv.myTokenParent, this._auth.FBToken, "", "FACEBOOK").subscribe((data : any) => {
          if (data=='errore') {
            this.msg='Credenziali non corrette!';
            this.showToast("danger");
          } else {
            this._mysrv.myToken = data;
            localStorage.setItem("tokenparent", this._mysrv.myTokenParent);
            localStorage.setItem("tokenfollow", this._mysrv.myToken);
            this.msg='Benvenuto in HomeSurfing!';
            this.showToast("primary");
            this.navController.navigateRoot('/tabs');
          };
         }, error => {
            
        }); 
      }
    }


    _Gfollow(){
      if (this._auth.GLoggedIn) {
        if (this.modeDirectLoginNegozio) {
          this._Gfollowdirect();
        }
        else {

          if (this._mysrv.myTokenParent) {
            this._auth.follownew(this._mysrv.myTokenParent, "", this._auth.GToken, "GOOGLE").subscribe((data : any) => {
              if (data=='errore') {
              } else {
                this._mysrv.myToken = data;
                localStorage.setItem("tokenparent", this._mysrv.myTokenParent);
                localStorage.setItem("tokenfollow", this._mysrv.myToken);
              };
             }, error => {
            }); 
          }

        this._auth.follownew2("", this._auth.GToken, "GOOGLE").subscribe((data : any) => {
          if (data=='errore') {
            this.msg='Credenziali non corrette!';
            this.showToast("danger");
          } else {
            this.showNegozi=true;
            this.DatiNegozi = data;
            if (this.DatiNegozi.length==1) {
              this._opennegozio(this.DatiNegozi[0]);
            }
            this.msg='Benvenuto in HomeSurfing!';
            this.showToast("primary");
          };
         }, error => {
            
        }); 
      } 
    }
    }
    _Gfollowdirect(){
      if (this._auth.GLoggedIn) {
        this._auth.follownew(this._mysrv.myTokenParent, "", this._auth.GToken, "GOOGLE").subscribe((data : any) => {
          if (data=='errore') {
            this.msg='Credenziali non corrette!';
            this.showToast("danger");
          } else {
            this._mysrv.myToken = data;
            localStorage.setItem("tokenparent", this._mysrv.myTokenParent);
            localStorage.setItem("tokenfollow", this._mysrv.myToken);
            this.msg='Benvenuto in HomeSurfing!';
            this.showToast("primary");
            this.navController.navigateRoot('/tabs');
          };
         }, error => {
            
        }); 
      }
    }

    gotocookies(){
      this.navController.navigateForward('/cookies');
    }

    _logout(){
      if (this._auth.logoutfollower()==true) {
        //this.router.navigate(['/login', this._myglobal.myTokenParent]);
        this.router.navigate(['/login']);
      }
    }

    _opennegozio(n) {
      this._mysrv.myToken = n.token;
      this._mysrv.myTokenParent = n.tokennegozio;
      localStorage.setItem("tokenparent", this._mysrv.myTokenParent);
      localStorage.setItem("tokenfollow", this._mysrv.myToken); 
      this.msg='Benvenuto in ' + n.nomenegozio + '!';
      this.showToast("primary");
      this._prof.loadProfiloPubNew();
      this.navController.navigateRoot('/tabs/tab1');
    }
 
      public msg: string ="";
      public async showToast(_color: string) {
    
        const toast: any = await this.toastController.create({
          message: this.msg,
          duration: 1500,
          position: "top",
          color: _color
        });
        await toast.present();
          this.msg="";
        }
  }
