import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurfglobalService } from "../services/surfglobal.service";

@Injectable({
  providedIn: 'root'
})
export class OrdiniService {

  constructor(private httpClient: HttpClient,public _global: SurfglobalService) { }

  loadB_Ordini(idcliente) {
    let postData;
    if (idcliente >0) {
        postData = {
          "token": this._global.myToken,
          "idcliente": idcliente
        }
    } else {
      postData = {
        "token": this._global.myToken
        }
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/ordini_utente", postData, headers) 
  }

  loadF_OrdiniCliente() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/ordini_cliente", postData, headers) 
  }

  loadF_OrdiniClienteByIDOrdine(idordine) {
    let postData = {
            "token": this._global.myToken,
            "idordine" : idordine
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/ordini_cliente", postData, headers) 
  }

  addF_Ordine(note, tipodelivery, indirizzoconsegna, nomeconsegna, dataoraconsegna, telefono, idrc, prodotti) {
    let postData = {
            "token": this._global.myToken,
            "note": note,
            "tipodelivery" : tipodelivery,
            "indirizzoconsegna" : indirizzoconsegna,
            "nomeconsegna" : nomeconsegna,
            "dataoraconsegna" : dataoraconsegna,
            "telefono" : telefono,
            "idrc" : idrc,
            "prodotti" : prodotti
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/addordinecliente", postData, headers) 
  }
  
  updB_Stato(idordine, stato) {
    let postData = {
            "token": this._global.myToken,
            "idordine": idordine,
            "stato" : stato
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/updordinestato", postData, headers) 
  }


  putPaypal(idordine, jsonret) {
    let postData = {
            "token": this._global.myToken,
            "idordine": idordine,
            "jsonret" : jsonret 
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/put_paypal", postData, headers) 
  }

  pagamentoconsegna(idordine) {
    let postData = {
            "token": this._global.myToken,
            "idordine": idordine
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/ordine_pagamento_consegna", postData, headers) 
  }
  
  loadSpecificheRitiro() {
    let postData = {
            "token": this._global.myToken
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "HOMEUser/getspecificheritiro", postData, headers) 
  }

  loadSpecificheConsegna() {
    let postData = {
            "token": this._global.myToken
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "HOMEUser/getspecificheconsegna", postData, headers) 
  }

  getpdfOrdine(idordine) {
    let postData = {
            "token": this._global.myToken,
            "idordine" : idordine
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/getpdfordine", postData, headers) 
  }
  
}