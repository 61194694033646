import { Component, OnInit } from '@angular/core'; 
import { HttpClient} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; 
import { NavController } from '@ionic/angular';
import { ProdottiService, Categoria, Prodotto } from "../services/prodotti.service";
import { CartService, Product } from "../services/cart.service";
import { SurfglobalService } from '../services/surfglobal.service';

@Component({
  selector: 'app-tab4-b',
  templateUrl: './tab4-b.page.html',
  styleUrls: ['./tab4-b.page.scss'],
})
export class Tab4BPage implements OnInit {
  public mySearch: string ="";
  public Prodotti: Prodotto[] = [];
  public ProdottiAll: Prodotto[] = [];
  
  public Categorie: Categoria[] = [];
  public idcat_selected=null;
  public cat_selected=null;

  constructor(public httpClient: HttpClient, private route: ActivatedRoute, private router: Router, public _mysrv: ProdottiService, private navCtrl: NavController, public _mycart: CartService,  public _myglobal: SurfglobalService) {
    console.log(this._myglobal.license);
  }

 
    loadData(){
      if (this.idcat_selected>0) {
        console.log('LOAD PRODOTTI:' +this.idcat_selected);
          this._mysrv.loadProdottiByCategoria(this.idcat_selected).subscribe((data : any) => {
            this.Prodotti = data;
            this.ProdottiAll = JSON.parse(JSON.stringify(data));
            console.log(data);
          }, error => {
            console.log(error);
          });
      } else {
            console.log('LOAD CATEG:' +this.idcat_selected);
            this._mysrv.loadCategorie().subscribe((data : any) => {
            this.Categorie = data;
            console.log(JSON.stringify(data));
        }, error => {
          console.log(error);
        });

      }
  }



   onInput(evt) {
    //console.log(evt.target.value);
    this.mySearch = evt.target.value;
    if (evt.target.value != null && evt.target.value.length>0) {
      this.Prodotti = JSON.parse(JSON.stringify(this.ProdottiAll));
        let counter = 0;
        let ProdottiTemp: any[] =[];
        ProdottiTemp = Array.from(this.Prodotti);
        ProdottiTemp.forEach((item,index,obj) => {
          if (item.prodotto.toLowerCase().includes(this.mySearch.toLowerCase())) {
          } else{
            this.Prodotti.splice(index-counter, 1);
            counter++;
          }
        });
   
   
    } else {
      this.Prodotti = JSON.parse(JSON.stringify(this.ProdottiAll));
    }
  }
 
  onCancel(evt) {
    this.Prodotti = this.ProdottiAll;
  }
  
  _addcart(myProd) {
    console.log(JSON.stringify(myProd.iditem));
    var pro: Product[] = [
      { iditem: myProd.iditem, prodotto: myProd.prodotto, prezzo: myProd.prezzo, qta: 1, img_thumb: myProd.img_thumb}
    ];
    this._mycart.addProduct(pro[0]);
  }

  public doRefresh(event) {
    this.loadData();
    event.target.complete();
  }
  navigate(id){
    this.navCtrl.navigateForward(['/tabs/prodottiedit', {iditem:id, idcat:this.idcat_selected, cat:this.cat_selected}]);
  }
  //ngAfterContentInit(){
   // this.loadData();
  //}
  ngOnInit(){  
  }
  ionViewDidEnter() {
    this.route.paramMap.subscribe(params => {
      this.idcat_selected = params.get('idcat');
      this.cat_selected = params.get('cat');
   });
   console.log('IDCAT BACK2s:' +this.idcat_selected);
    this.Categorie=[];
      this.Prodotti=[];
      this.ProdottiAll=[];
      this.loadData();
  }


}
