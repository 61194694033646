import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurfglobalService } from "../services/surfglobal.service";


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  @Output() Evento = new EventEmitter;

  constructor(private httpClient: HttpClient,public _global: SurfglobalService) { }
 
  loadProfilo() {
    let postData = {
      "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "homeuser/datiutente", postData, headers) 
  }

  loadProfiloPub() {
    let postData = {
      "token": this._global.myToken
    }
    console.log("LOADPROFILOPUB");
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "CLIENTE/datinegoziopub", postData, headers) 
  }

    
  loadProfiloPubNew() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL + "CLIENTE/datinegoziopub", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
       }, error => {
        console.log(error);
      });
  }

  loadCliente() {
    let postData = {
      "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "CLIENTE/daticliente", postData, headers) 
  }


  saveProfilo(Profilo: any) {
          let postData = {
            "token": this._global.myToken,
            "pi" : Profilo.pi,
            "nome": Profilo.nome,
            "cap" : Profilo.cap,
            "citta": Profilo.citta,
            "telefonopub": Profilo.telefonopub,
            "mailpub" : Profilo.mailpub,
            "oraripub" : Profilo.oraripub,
            "altreinfopub" :Profilo.altreinfopub, 
            "descrizionepub" : Profilo.descrizionepub,
            "indirizzonegozio" : Profilo.indirizzonegozio
        }
        let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

       return this.httpClient.post(this._global.myURL + "homeuser/updatedatiutente", postData, headers) 
  }

  saveUtente(indirizzo: any, telefono: any) {
    let postData = {
      "token": this._global.myToken,
      "indirizzo" : indirizzo,
      "telefono" : telefono
  }
  let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

 return this.httpClient.post(this._global.myURL + "CLIENTE/updatedaticliente", postData, headers) 
}


  savePhotoProfilo(b64) {
    let postData = {
            "token": this._global.myToken,
            "img" : b64 
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}
    return this.httpClient.post(this._global.myURL + "homeuser/addmainfotonegozio", postData, headers) 
  }
  

  getTimeStamp(){
    return new Date().getTime();
  }

}
