import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SurfglobalService {
  public myTokenParent: string ="";
  public myToken: string = "";
  public myURL: string = "https://api.homesurfing.store/api/";
  
  public notify_msgnonletti: any;
  public license: any;

  public PayPalID: string ="";
  

  constructor() { 
    if (localStorage.getItem("tokenparent")) {
      this.myTokenParent = localStorage.getItem("tokenparent") 
    }
    if (localStorage.getItem("tokenfollow")) {
      this.myToken = localStorage.getItem("tokenfollow") 
    }
  }


  getTimeStamp(){
    return new Date().getTime();
  }

  
}
