import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurfglobalService } from "../services/surfglobal.service";

@Injectable({
  providedIn: 'root'
})
export class PrenotazioniService {
  private idspecselected: any;
  private idspec

  constructor(private httpClient: HttpClient,public _global: SurfglobalService) {      
  }
  
  loadAttivita() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prenotazioni/attivita", postData, headers) 
  }

  loadSpecificheAttivita(idattivita) {
    let postData = {
            "token": this._global.myToken,
            "idattivita": idattivita
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prenotazioni/specificheattivita", postData, headers) 
  }

  loadSpecificheValori(idspec, specdetailselectedlist, dataordine) {
    let postData = {
            "token": this._global.myToken,
            "idspec": idspec,
            "specdetailselectedlist": specdetailselectedlist,
            "dataordine": dataordine
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prenotazioni/specifichevalori", postData, headers) 
  }

  add_Prenotazione(note, dataprenotazione, prodotti) {
    let postData = {
            "token": this._global.myToken,
            "note": note,
            "prenotazione" : true,
            "dataprenotazione" : dataprenotazione, 
            "prodotti" : prodotti
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "ORDINI/addordinecliente", postData, headers) 
  }

  loadPrenotazioniGiorni(iditem, dal, al) {
    let postData = {
            "token": this._global.myToken,
            "iditem" : iditem,
            "dal" : dal,
            "al" : al
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prenotazioni/giorni", postData, headers) 
  }

  loadPrenotazioniGiorno(iditem, giorno) {
    let postData = {
            "token": this._global.myToken,
            "iditem" : iditem,
            "giorno" : giorno
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prenotazioni/giorno", postData, headers) 
  }

  loadPrenotazioni() {
    let postData = {
            "token": this._global.myToken,
            "prenotazione" : true,
            "soloprodotti" : true
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prodotti/prodotti", postData, headers) 
  }


  loadPrenotazioniGiorniApp(iditem, dal, al) {
    let postData = {
            "token": this._global.myToken,
            "iditem" : iditem,
            "dal" : dal,
            "al" : al
    }
    console.log(postData);
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prenotazioni/giornicalendario", postData, headers) 
  }


}
