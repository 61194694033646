import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Product {
  iditem: number;
  prodotto: string;
  prezzo: number;
  qta: number;
  img_thumb:  string;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  data: Product[] = [];
 
  private cart = [];
  private cartItemCount = new BehaviorSubject(0);
 
  constructor() {}
 
  getProducts() {
    return this.data;
  }
 
  getCart() {
    return this.cart;
  }
 
  getCartItemCount() {
    return this.cartItemCount;
  }
 
  addProduct(product) {
    //console.log(JSON.stringify(product));
    let added = false;
    for (let p of this.cart) {
      if (p.iditem === product.iditem) {
        p.qta += 1.00;
        added = true;
        break;
      }
    }
    if (!added) {
      if (product.qta == null || product.qta==0) {
      product.qta = 1;
      }
      this.cart.push(product);
    }
    this.cartItemCount.next(this.cartItemCount.value + 1);
    console.log(JSON.stringify(this.cart));
  }
 
  decreaseProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.iditem === product.iditem) {
        p.qta -= 1;
        if (p.qta == 0) {
          this.cart.splice(index, 1);
        }
      }
    }
    this.cartItemCount.next(this.cartItemCount.value - 1);
  }
 
  removeProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.iditem === product.iditem) {
        this.cartItemCount.next(this.cartItemCount.value - p.qta);
        this.cart.splice(index, 1);
      }
    }
  }
  
  clearCart(){
    this.cart = [];
    this.cartItemCount.next(0);
  }




}