import { DOCUMENT } from '@angular/common';
import { Product, CartService } from './../services/cart.service';
import { Component, OnInit,  Inject, Injectable } from '@angular/core';
import { ModalController, AlertController, ToastController, NavController } from '@ionic/angular';
import { OrdiniService } from '../services/ordini.service'
import { ProfileService } from "../services/profile.service";
import { SurfglobalService } from "../services/surfglobal.service";

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.page.html',
  styleUrls: ['./cart-modal.page.scss'],
})
export class CartModalPage implements OnInit {

  paymentAmount: string = '0.00';
  currency: string = 'EUR';
  currencyIcon: string = '€';
  responseData: any = '';


  note: string="";
  tipodelivery: string= "";
  indirizzoconsegna: string = "";
  nomeconsegna: string = "";
  telefono: string = "";
  idrc;
  rc;
  dataoraconsegna: string = new Date().toISOString();
  cart: Product[] = [];
  SpecificheRitiro: any =[];
  SpecificheConsegna: any =[];

  public idordinereturn;
  
  constructor(@Inject(DOCUMENT) private document: Document, public _myglobal: SurfglobalService, private cartService: CartService, private ordiniService: OrdiniService,  private profileService: ProfileService, private modalCtrl: ModalController, public toastController: ToastController, private alertCtrl: AlertController, private navCtrl: NavController) {

    const paypalScriptElement: HTMLScriptElement = this.document.createElement('script');

    paypalScriptElement.src = "https://www.paypal.com/sdk/js?client-id=" + this._myglobal.PayPalID + "&currency=EUR&disable-funding=sofort,mybank";
    paypalScriptElement.id = 'paypal-script';

    this.document.head.appendChild(paypalScriptElement);

    // let _this = this;
    // setTimeout(() => {
    //   // Render the PayPal button into #paypal-button-container
    //   <any>window['paypal'].Buttons({

    //     // Set up the transaction
    //     createOrder: function (data, actions) {
    //       return actions.order.create({
    //         purchase_units: [{
    //           amount: {
    //             value: _this.paymentAmount
    //           }
    //         }]
    //       });
    //     },

    //     // Finalize the transaction
    //     onApprove: function (data, actions) {
    //       return actions.order.capture()
    //         .then(function (details) {
    //           // Show a success message to the buyer
    //           alert('Transaction completed by ' + details.payer.name.given_name + '!');
    //         })
    //         .catch(err => {
    //           console.log(err);
    //         })
    //     }
    //   }).render('#paypal-button-container');
    // }, 500)
   }
 
  ngOnInit() {
    this._loadSpecifiche();
    this.cart = this.cartService.getCart();
  }
 
  decreaseCartItem(product) {
    this.cartService.decreaseProduct(product);
    if (this.cart== null || this.cart == [] || this.cart.length==0 ) {
      this.navCtrl.back();
    }
  }
 
  increaseCartItem(product) {
    this.cartService.addProduct(product);
  }
 
  removeCartItem(product) {
    this.cartService.removeProduct(product);
    if (this.cart== null || this.cart == [] || this.cart.length==0 ) {
      this.navCtrl.back();
    }
  }
 
  getTotal() {
    this.paymentAmount = this.cart.reduce((i, j) => i + j.prezzo * j.qta, 0).toFixed(2);
    return this.cart.reduce((i, j) => i + j.prezzo * j.qta, 0);
  }
 
  close() {
    this.modalCtrl.dismiss();
  }
 
  private msg: string = "";
  private title: string = ""; 
  async showAlert() {
    let alert = await this.alertCtrl.create({
      header: this.title,
      message: this.msg,
      buttons: ['OK']
    });
    alert.present().then(() => {
      this.modalCtrl.dismiss();
      console.log(this.idordinereturn);
      this.navCtrl.navigateBack(['/tabs/ordiniedit', this.idordinereturn]);
      //this.navCtrl.back();

    });
  }

  private msgtoast ="";
  public async showToast(_color: string) {

    const toast: any = await this.toastController.create({
      message: this.msgtoast,
      duration: 1500,
      position: "top",
      color: _color
    });
    await toast.present();
      this.msg="";
    }

  _addOrdine() {

    
    if (this.cart==null || this.cart.length==0) {
      this.msgtoast='Impossibile eseguire un ordine vuoto.';
      this.showToast("warning");
      return;
    }
    
    if (this.tipodelivery==null || this.tipodelivery.length==0) {
      this.msgtoast='Specififare il tipo di consegna.';
      this.showToast("warning");
      return;
    }
    if (this.tipodelivery=='C' && (this.indirizzoconsegna==null || this.indirizzoconsegna.length==0)) {
      this.msgtoast='Specififare il luogo di consegna.';
      this.showToast("warning");
      return;
    }
    if (this.nomeconsegna==null || this.nomeconsegna.length==0) {
      this.msgtoast='Specififare il nominativo.';
      this.showToast("warning");
      return;
    }
    if (this.dataoraconsegna==null || this.dataoraconsegna.length==0) {
      this.msgtoast='Specififare Data e Ora della richiesta.';
      this.showToast("warning");
      return;
    }
    if (this.telefono==null || this.telefono.length==0) {
      this.msgtoast='Specififare il numero di telefono.';
      this.showToast("warning");
      return;
    }
    if (this.tipodelivery=='C' && this.SpecificheConsegna.length>0) {
      if (this.idrc==null || this.idrc<=0) {
        this.msgtoast='Specififare la specifica di consegna.';
        this.showToast("warning");
        return;
      }
    }
    if (this.tipodelivery=='R' && this.SpecificheRitiro.length>0) {
      if (this.idrc==null || this.idrc<=0) {
        this.msgtoast='Specififare la specifica di ritiro.';
        this.showToast("warning");
        return;
      }
    }
    

    this.ordiniService.addF_Ordine(this.note, this.tipodelivery, this.indirizzoconsegna, this.nomeconsegna, this.dataoraconsegna, this.telefono, this.idrc, this.cart).subscribe((data : any) => {
      if (data=='errore') {
        this.title ='Errore ordine';
        this.msg='Impossibile eseguire la richiesta';
        this.showAlert();
      } else {
        this.idordinereturn = data;
        this.cart = [];
        this.cartService.clearCart();
        this.title='Grazie per il tuo ordine!';
        this.msg="Attenzione, l'ora proposta è indicativa. Ci riserviamo di confermarla a breve. Grazie per il tuo ordine!";
        this.showAlert();
       
      };
     }, error => {
      this.title ='Errore Ordine';
      this.msg= error;
      this.showAlert();
    }); 
     
  }

  onRadioChanged(evt){
    this._loadSpecifiche();
    if (this.tipodelivery=="R"){
      this.profileService.loadProfiloPub().subscribe((data : any) => {
        this.indirizzoconsegna = data.indirizzonegozio;
      }, error => { });
      this.profileService.loadCliente().subscribe((data : any) => {
        this.nomeconsegna = data.nome;
        this.telefono = data.telefono;
      }, error => { });
    }
    if (this.tipodelivery=="C"){
        this.profileService.loadCliente().subscribe((data : any) => {
          this.indirizzoconsegna = data.indirizzo;
          this.telefono = data.telefono;
          this.nomeconsegna = data.nome;
        }, error => { });
    }
  }
   
  public fixNumber(p){
    let value = p.qta;
    let prefix:string = value.toString().split('.')[0];
    let suffix:string = value.toString().split('.')[1];
    if(suffix.length > 3){
      let suffix_subed = suffix.substr(0,3);
      let new_value = prefix+'.'+suffix_subed;
      p.qta = new_value;
    }
  }
  getTimeStamp(){
    return new Date().getTime();
  }

 
 _loadSpecifiche(){
    this.ordiniService.loadSpecificheConsegna().subscribe((data : any) => {
      this.SpecificheConsegna = data;
      console.log(data);
    }, error => {
      this.msg= error;
      console.log(error);
      this.showToast("danger");
    });
  this.ordiniService.loadSpecificheRitiro().subscribe((data : any) => {
    this.SpecificheRitiro = data;
    console.log(data);
  }, error => {
    this.msg= error;
    console.log(error);
    this.showToast("danger");
  });
 }



 

}