import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurfglobalService } from "../services/surfglobal.service";

export interface Prodotto {
  token: string;
  iditem : number;
  categoria : string;
  prodotto : string;
  prodottospecs : string;
  prezzo : number;
  img: string;
  visibile: boolean;
}

export interface Categoria {
  categoria: string;
  prodotti : Prodotto[];
}


@Injectable({
  providedIn: 'root'
})
export class ProdottiService {
  @Output() Evento = new EventEmitter;

  constructor(private httpClient: HttpClient,public _global: SurfglobalService) {      
  }
  
  loadProdottiNew() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL + "prodotti/prodotti", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
       }, error => {
        console.log(error);
      });
  }

  loadProdotti() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prodotti/prodotti", postData, headers) 
  }
  loadProdottiByCategoria(idcat) {
    let postData = {
            "token": this._global.myToken,
            "idcategoria": idcat,
            "soloprodotti": true
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prodotti/prodotti", postData, headers) 
  }
 
  loadProdotto(iditem) {
    if (iditem>0) {
      let postData = {
              "token": this._global.myToken,
              "iditem" : iditem
      }
      let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

     return this.httpClient.post(this._global.myURL + "prodotti/prodotto", postData, headers) 
    }
  }

  delProdotto(iditem) {
    let postData = {
            "token": this._global.myToken,
            "iditem" : iditem
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

    return this.httpClient.post(this._global.myURL + "prodotti/delprodotto", postData, headers) 
  }

  saveProdotto(Prodotto: any, b64: string) {
    let postData = {
            "token": this._global.myToken,
            "iditem" : Prodotto.iditem,
            "categoria" : Prodotto.categoria,
            "codicearticolo" : Prodotto.codicearticolo,
            "prodotto" : Prodotto.prodotto,
            "prodottospecs" : Prodotto.prodottospecs,
            "prezzo" : Prodotto.prezzo,
            "img" : b64,
            "visibile" : Prodotto.visibile
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}
    return this.httpClient.post(this._global.myURL + "prodotti/editprodotto", postData, headers) 
  }

  addProdotto(Prodotto: any, b64: string) {
    let postData = {
            "token": this._global.myToken,
            "categoria" : Prodotto.categoria,
            "codicearticolo" : Prodotto.codicearticolo,
            "prodotto" : Prodotto.prodotto,
            "prodottospecs" : Prodotto.prodottospecs,
            "prezzo" : Prodotto.prezzo,
            "img" : b64,
            "visibile" : Prodotto.visibile
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}

    return this.httpClient.post(this._global.myURL + "prodotti/addprodotto", postData, headers) 
  }

  savePhotoProdotto(iditem, b64) {
    let postData = {
            "token": this._global.myToken,
            "iditem" : iditem,
            "img" : b64 
    }
    let headers = {headers:{ 'Accept':"application/json", 'content-type':"application/json" }}
    return this.httpClient.post(this._global.myURL + "prodotti/addmainfotoprodotto", postData, headers) 
  }


  loadCategorie() {
    let postData = {
            "token": this._global.myToken
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "prodotti/prodotticategorie", postData, headers) 
  }

  getTimeStamp(){
    return new Date().getTime();
  }

}
