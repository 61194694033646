import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { HttpClientModule } from '@angular/common/http';

import { CartModalPageModule } from './cart-modal/cart-modal.module';
import { NgPipesModule } from 'ngx-pipes'; 
import { PayPal, PayPalPayment, PayPalConfiguration } from '@ionic-native/paypal/ngx';
import { Tab4BPage } from './tab4-b/tab4-b.page';
import { Tab4Page } from './tab4/tab4.page';
import { Tab5Page } from './tab5/tab5.page';
import { OrdiniPage } from './ordini/ordini.page';
import { PrenotazioniPage } from './prenotazioni/prenotazioni.page';
import { CartModalPage } from './cart-modal/cart-modal.page';
import { LoginPage } from './login/login.page';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';


let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("508099962592-dmtfqlgtjmq0jegt02hp88f0rkvffbl7.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("1156256841522836")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,SocialLoginModule, HttpClientModule, CartModalPageModule, NgPipesModule, NgxQRCodeModule, NgxExtendedPdfViewerModule],
  providers: [
    StatusBar,
    SplashScreen, 
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: AuthServiceConfig, useFactory: provideConfig},
    PayPal,
    Tab4BPage,Tab4Page, Tab5Page, OrdiniPage, PrenotazioniPage, CartModalPage, LoginPage
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
