import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, ContentChild } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NavController, IonContent } from '@ionic/angular';
import { ToastController, ModalController, AlertController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { PrenotazioniService } from "../services/prenotazioni.service";
import { IonSlides } from '@ionic/angular';
import { ProdottiService } from '../services/prodotti.service';

@Component({
  selector: 'app-prenotazioni',
  templateUrl: './prenotazioni.page.html',
  styleUrls: ['./prenotazioni.page.scss'],
})

export class PrenotazioniPage implements OnInit {
 @ViewChild('slides') slides: IonSlides; 
 @ViewChild(IonContent, {static: true}) content: IonContent;


 public Attivita: any = []; 
  public SpecificheAttivita: any = []; 
  public SpecificheValori: any = []; 

  public dataprenotazione: string = new Date().toISOString();
  public idspecdetailselected: any;
  public specdetailselected: any = [];
  public idspecselected: any;
  public specselected: any;
  slideindex: any;
  public idattivita: any;
  public attivita: string;
  public note: string;

  public cartProdotti: any = [];

  constructor(public httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private navCtrl: NavController, private modalCtrl: ModalController, public toastController: ToastController, private alertCtrl: AlertController, public loading: LoadingController, public _mysrv: PrenotazioniService, public _myprod: ProdottiService, public _mypre: PrenotazioniService) { 
  }
     
  ngOnInit() {
    this._loadAttivita();
  }
  public doRefresh(event) {
    this._loadAttivita();
    event.target.complete();
  }
  ionViewDidEnter() {
    this._loadAttivita();
  }
  ionViewWillEnter() {
    this._loadAttivita();
    this.slides.slideTo(0);
  }
  _loadAttivita(){
    this._mysrv.loadAttivita().subscribe((data : any) => {
      this.Attivita = data;
      this.idattivita = null;
      this.attivita = "";
      this.viewcalendar=false;
      this.cartProdotti = [];
      this.idspecdetailselected = null;
      this.specselected = "";
      this.idspecselected = null;
      console.log(JSON.stringify(this.Attivita))
      console.log('RELOAD: ' + this.idattivita);
    }, error => {
      this.msg= error;
      console.log(error);
      this.showToast("danger");
    });
 }

 _loadSpecificheAttivita(idattivita){
  this._mysrv.loadSpecificheAttivita(idattivita).subscribe((data : any) => {
    this.SpecificheAttivita = data;
    console.log( this.SpecificheAttivita);
  }, error => {
    this.msg= error;
    console.log(error);
    this.showToast("danger");
  });
}

_loadValoriAttivita(idspec, dataordine){
  //console.log(JSON.stringify(this.cartProdotti));
  this._mysrv.loadSpecificheValori(idspec, this.cartProdotti, dataordine).subscribe((data : any) => {
    this.SpecificheValori = data;
    console.log(JSON.stringify(this.SpecificheValori));
  }, error => {
    this.msg= error;               
    console.log(error);
    this.showToast("danger");
  });
}

slideChanged() { 
  this.content.scrollToTop(400);
  this.slides.getActiveIndex().then(index => {
    this.slideindex = index-1;
    
    this.idspecselected = this.SpecificheAttivita[this.slideindex].idspec;
    this.specselected = this.SpecificheAttivita[this.slideindex].spec;
    
    this._loadValoriAttivita(this.SpecificheAttivita[this.slideindex].idspec, this.dataprenotazione)
  });
} 

segmentChanged(ev: any, id: any) {
  console.log(ev);
  //per recuperare la descrizione del valore dal radio
  for (let p of this.Attivita) {
    if (p.idattivita == id) {
       this.attivita=p.attivita;
    }
  }
  // end
  this._loadSpecificheAttivita(id);
}
selectattivita(item){
  this.viewcalendar=true;
  this.idattivita = item.idattivita;
  this.attivita = item.attivita;
  this.getDaysOfMonth();
  this.loadPrenotazioneGiorni();
  this._loadSpecificheAttivita(this.idattivita);
}

next(abilitato) {
  if (abilitato==false) {
    this.msgtoast='La data selezionata non è prenotabile.';
    this.showToast("warning");
    return;
  }
  this.slides.getActiveIndex().then(index => {
    console.log(index);
    if (index==0) {
      if (!(this.idattivita>0)) {
        this.msgtoast='Specificare un valore per proseguire la prenotazione.';
        this.showToast("warning");
      } else {
        this._loadSpecificheAttivita(this.idattivita);
        this.slides.slideNext();
      }
    }
    if (index==(this.SpecificheAttivita.length)+2-1) {
      this.ok();
      return;
   }

    if (index>0) {
      //this.viewcalendar=false;
      console.log('>zero');
      if (this.idspecselected>0 && this.idspecdetailselected>0) {
        //per recuperare la descrizione del valore dal radio
        console.log(this.idspecselected + ' ' + this.idspecdetailselected);
        let specvalore: string;
        for (let p of this.SpecificheValori) {
          if (p.idspecdetail == this.idspecdetailselected) {
            specvalore=p.specdetail;
          }
        }
        //end
        if (specvalore!=null &&  specvalore!='') {
          var row: any[] = [
            {idspec: this.idspecselected, idspecdetail: this.idspecdetailselected, spec: this.specselected, specval: specvalore}
          ];
          this.cartProdotti.push(row[0]);
          console.log(this.cartProdotti);
          this.slides.slideNext();
        } else {
          this.msgtoast='Specificare un valore per proseguire la prenotazione.';
          this.showToast("warning");
        }
      } else {
        this.msgtoast='Specificare un valore per proseguire la prenotazione.';
        this.showToast("warning");
      } 
   }
   
});
}

prev() {
  // this.slides.getActiveIndex().then(index => {
  // for (let p of this.cartProdotti) {
  //   if (p.idspec === this.SpecificheAttivita[index-2].idspec) {
  //     this.cartProdotti.splice(index-2,1);
  //   }
  // }
  // if (index-1 <= 0) { 
  //   if (this.viewcalendar) {
  //     this.viewcalendar=false;
  //   } else {
  //   this._loadAttivita();
  //   }
  // }
  // })
  //console.log(JSON.stringify(this.cartProdotti));
  //console.log("end prev");
  //this.viewcalendar=false;
  //this.slides.slidePrev();
  this._loadAttivita();
  this.slides.slideTo(0);
}



ok(){

  var prodotti: any[] = [
    {
      iditem: this.idattivita,
      qta: 1,
      specs : this.cartProdotti
    }
  ]

  if (this.dataprenotazione==null || this.dataprenotazione.length==0) {
    this.msgtoast='Specificare Data e Ora della prenotazione.';
    this.showToast("warning");
    return;
  }

  if (this.SpecificheAttivita.length>0) {
  if (!(this.idattivita>0 && this.cartProdotti.length>0)) {
    this.msgtoast='Specificare un attività da prenotare.';
    this.showToast("warning");
    return;
  }}

  this._mysrv.add_Prenotazione(this.note, this.dataprenotazione, prodotti).subscribe((data : any) => {
    if (data=='errore') {
      this.title ='Errore prenotazione';
      this.msg='Impossibile eseguire la richiesta';
      this.showAlert();
    } else {
      this.cartProdotti = [];
      this.SpecificheValori = [];
      this.SpecificheAttivita = [];
      this.idspecselected = 0;
      this.idspecdetailselected = 0;
      this.slides.slideTo(0);
      this.note='';
      this.viewcalendar=false;
      this.slideindex=0;
      this._loadAttivita();
      this.navCtrl.navigateForward(['/tabs/ordini']);
      this.title='Grazie per il tua prenotazione!';
      this.msg="Attenzione, l'ora proposta è indicativa. Ci riserviamo di confermarla a breve. Grazie per la tua prenotazione!";
      this.showAlert();
      
    };
   }, error => {
    this.title ='Errore Prenotazione';
    this.msg= error;
    this.showAlert();
  }); 

}

 
 
  private msgtoast ="";
  public async showToast(_color: string) {

    const toast: any = await this.toastController.create({
      message: this.msgtoast,
      duration: 1500,
      position: "top",
      color: _color
    });
    await toast.present();
      this.msg="";
    }


    close() {
      this.modalCtrl.dismiss();
    }
   
    private msg: string = "";
    private title: string = ""; 
    async showAlert() {
      let alert = await this.alertCtrl.create({
        header: this.title,
        message: this.msg,
        buttons: ['OK']
      });
      alert.present().then(() => {
        this.modalCtrl.dismiss();
        //this.navCtrl.back();
      });
    }

    

  public viewcalendar: Boolean = false;
  public PrenotazioniGiorni: any = [];
  date: Date = new Date();
  daysInThisMonth: any;
  daysInLastMonth: any;
  daysInNextMonth: any;
  monthNames: string[] = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
  currentMonth: any;
  currentYear: any;
  currentDate: any;
  mydatestart: Date;
  mydateend: Date;

  getDaysOfMonth() {

    this.daysInThisMonth = new Array();
    this.daysInLastMonth = new Array();
    this.daysInNextMonth = new Array();
    this.currentMonth = this.monthNames[this.date.getMonth()];
    this.currentYear = this.date.getFullYear();
    if(this.date.getMonth() === new Date().getMonth()) {
      this.currentDate = new Date().getDate();
    } else {
      this.currentDate = 999;
    }
    var firstDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay();
    var prevNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();
    for(var i = prevNumOfDays-(firstDayThisMonth-1); i <= prevNumOfDays; i++) {
      this.daysInLastMonth.push(i);
    }
    console.log(this.date);
    this.mydatestart = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    console.log(this.mydatestart);
    var thisNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth()+1, 0).getDate();
    for (var i = 0; i < thisNumOfDays; i++) {
      this.daysInThisMonth.push(i+1);
      this.mydateend = new Date(this.date.getFullYear(), this.date.getMonth(), i+1);
    }
    console.log(this.mydateend);

    var lastDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth()+1, 0).getDay();
    var nextNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth()+2, 0).getDate();
    for (var i = 0; i < (6-lastDayThisMonth); i++) {
      this.daysInNextMonth.push(i+1);
    }
    var totalDays = this.daysInLastMonth.length+this.daysInThisMonth.length+this.daysInNextMonth.length;
    if(totalDays<36) {
      for(var i = (7-lastDayThisMonth); i < ((7-lastDayThisMonth)+7); i++) {
        this.daysInNextMonth.push(i);
      }
    }
  }
  goToLastMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 0);
    console.log(this.date);
    this.getDaysOfMonth();
    this.loadPrenotazioneGiorni();
  }
  goToNextMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth()+2, 0);
    console.log(this.date);
    this.getDaysOfMonth();
    this.loadPrenotazioneGiorni();
  }
  loadPrenotazioneGiorni(){

    this._mypre.loadPrenotazioniGiorniApp(this.idattivita, 
      this.mydatestart.toISOString(),
      this.mydateend.toISOString()).subscribe((data : any) => {
      this.PrenotazioniGiorni = data;
      console.log(this.PrenotazioniGiorni);
     
     }, error => {
      console.log(error);
    });
 }

}
