import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login/:token',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then(m => m.Tab4PageModule)
  },
  {
    path: 'tab4-b',
    loadChildren: () => import('./tab4-b/tab4-b.module').then( m => m.Tab4BPageModule)
  },
  {
    path: 'tab4-b/:idcat/:cat',
    loadChildren: () => import('./tab4-b/tab4-b.module').then( m => m.Tab4BPageModule)
  },
  {
    path: 'tab5',
    loadChildren: () => import('./tab5/tab5.module').then(m => m.Tab5PageModule)
  },
  {
    path: 'prodottiedit/:iditem/:idcat',
    loadChildren: () => import('./prodottiedit/prodottiedit.module').then( m => m.ProdottieditPageModule)
  },   
  {
    path: 'prodottiedit/:iditem',
    loadChildren: () => import('./prodottiedit/prodottiedit.module').then( m => m.ProdottieditPageModule)
  },   
  {
    path: 'ordini',
    loadChildren: () => import('./ordini/ordini.module').then( m => m.OrdiniPageModule)
  },
  {
    path: 'cart-modal',
    loadChildren: () => import('./cart-modal/cart-modal.module').then( m => m.CartModalPageModule)
  },
  {
    path: 'prenotazioni',
    loadChildren: () => import('./prenotazioni/prenotazioni.module').then( m => m.PrenotazioniPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },   
  {
    path: 'ordiniedit/:idordine',
    loadChildren: () => import('./ordiniedit/ordiniedit.module').then( m => m.OrdinieditPageModule)
  },
  {
    path: 'negozi',
    loadChildren: () => import('./negozi/negozi.module').then( m => m.NegoziPageModule)
  },
  {
    path: 'cookies',
    loadChildren: () => import('./cookies/cookies.module').then( m => m.CookiesPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
