import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurfglobalService } from "../services/surfglobal.service";
import { Plugins } from '@capacitor/core';
import "@codetrix-studio/capacitor-google-auth";

@Injectable({
  providedIn: 'root'
})
export class AutenticationService {
  @Output() Evento = new EventEmitter;

  public DatiPub: any = [];
  public user: any =[];
  constructor(private httpClient: HttpClient,public _global: SurfglobalService) { }

  public FBLoggedIn: boolean = false;
  public FBuser: any = [];
  public FBToken: string = "";
    
    async FBsignOut(): Promise<void> {
      // await Plugins.FacebookLogin.logout();
      this.FBuser=null;
      this.FBLoggedIn=false;
      this.FBToken ="";
      localStorage.removeItem("FBToken");

      this.Guser=[];
      this.GLoggedIn=false;
      this.GToken ="";
      localStorage.removeItem("GToken");
      
    }
  
    async FBgetUserInfo() {
      const response = await fetch(`https://graph.facebook.com/me?fields=id,name,gender,link,picture,email&type=large&access_token=${this.FBToken}`);
      const myJson = await response.json();
      this.FBuser = myJson;
      console.log(this.FBuser);
      if (this.FBuser) {
        this.FBLoggedIn=true;
      }
    }
  
    async FBgetCurrentState() {
      const result = await Plugins.FacebookLogin.getCurrentAccessToken();
      try {
        console.log("getCurrentAccessToken");
        console.log(result);
        if (result && result.accessToken) {
          let user = { token: result.accessToken.token}
          console.log(user);
          this.FBgetUserInfo();
          this.FBLoggedIn=true;
          localStorage.setItem("FBToken", result.accessToken.token);
        }
      } catch (e) {
        console.log(e)
      }
    }
   

    async FBsignIn(): Promise<void> {
      const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
      const result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
      console.log(JSON.stringify(result));
      if (result && result.accessToken) {
        this.FBToken = result.accessToken.token;
        let user = { token: result.accessToken.token }
        console.log(user);
        this.FBgetUserInfo();
        this.FBLoggedIn=true;
        localStorage.setItem("FBToken", result.accessToken.token);
      }
    }



    public Guser: any = {};
    public GLoggedIn: boolean = false;
    public GToken: string = "";
    props:any = {};
    async GsignIn(): Promise<void> {
      const { history } = this.props;
      const result = await Plugins.GoogleAuth.signIn();
      //console.info('result', result);
      if (result) {
        this.Guser=[result];
        this.GLoggedIn=true;
        this.GToken=this.Guser[0].authentication.idToken;
        localStorage.setItem("GToken", JSON.stringify(this.Guser));
        //console.log('' + this.GToken);
        history.push({
          pathname: '/home',
          state: { name: result.name || result.displayName, image: result.imageUrl, email: result.email }
        });
      }
    }

    async GgetUserInfo() {
      this.GLoggedIn=true;
      this.GToken=this.Guser[0].authentication.idToken;
      //console.log('' + this.GToken);
    }


    async GsignOut(): Promise<void> {
     
      this.Guser=[];
      this.GLoggedIn=false;
      this.GToken ="";
      localStorage.removeItem("GToken");

      this.FBuser=null;
      this.FBLoggedIn=false;
      this.FBToken ="";
      localStorage.removeItem("FBToken");

      await Plugins.GoogleAuth.signOut();
    }

  login(username: string, password: string) {
    let postData = {
            "l_user": username,
            "l_pwd": password
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "homeuser/login", postData, headers) 
  }

  recuperapwd(username: string) {
    let postData = {
            "mail": username
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "homeuser/nuovapwd", postData, headers) 
  }

  
  follow(tokennegozio: string, mail: string, nome: string, urlimg: string) {
     
    let postData = {
            "tokennegozio" : tokennegozio,
            "mailcliente": mail,
            "nome": nome,
            "urlimg": urlimg
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "CLIENTE/follow", postData, headers) 
  }

  follownew(tokennegozio: string, authToken: string, idToken: string, Provider: string ) {
    let postData;
    if (Provider=="GOOGLE") {
      postData = {
        "tokennegozio" : tokennegozio,
        "googletoken" : idToken 
      }
    }
    if (Provider=="FACEBOOK") {
      postData = {
        "tokennegozio" : tokennegozio,
        "facebooktoken" : authToken 
      }
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
     
    return this.httpClient.post(this._global.myURL + "CLIENTE/follow", postData, headers) 
  }

  follownew2(authToken: string, idToken: string, Provider: string ) {
    let postData;
    if (Provider=="GOOGLE") {
      postData = {
        "googletoken" : idToken 
      }
    }
    if (Provider=="FACEBOOK") {
      postData = {
        "facebooktoken" : authToken 
      }
    }
    console.log("follow2");
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
     
    return this.httpClient.post(this._global.myURL + "CLIENTE/followhome", postData, headers) 
  }
     
  datipub() {
    let postData = {
          "tokennegozio": this._global.myTokenParent
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL + "CLIENTE/datipub", postData, headers)
      .subscribe(data => {
        this.DatiPub = data;
        this.Evento.emit(data);       
       }, error => {
        console.log(error);
      });
  }



  logout(){
    localStorage.removeItem("username");
    localStorage.removeItem("pwd");
    localStorage.removeItem("savecredential");
    this._global.myToken="";
    return true;
  }

  logoutfollower(){
    localStorage.removeItem("tokenfollow");
    this._global.myToken="";
    return true;
  }



  
  getchatidlogged(chiave: string) {
    let postData = {
            "chiave" : chiave
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "CLIENTE/getchatidlogged", postData, headers) 
  }


  
  social_link(link_genericmail, link_facebook, link_google, link_telegram) {
    let postData = {
            "token" : this._global.myToken,
            "link_genericmail": link_genericmail,
            "link_facebook": link_facebook,
            "link_google": link_google,
            "link_telegram": link_telegram
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "CLIENTE/link", postData, headers) 
  }


  social_unlink(genericmail: boolean, facebook: boolean, google: boolean, telegram: boolean) {
    let postData = {
            "token" : this._global.myToken,
            "genericmail": genericmail,
            "facebook": facebook,
            "google": google,
            "telegram": telegram
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}

    return this.httpClient.post(this._global.myURL + "CLIENTE/unlink", postData, headers) 
  }


}
